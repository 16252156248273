import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MagnifyingGlass, X } from 'phosphor-react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { searchGtag } from 'Analytics/dataLayerEvents';
import searchIcon from 'assets/icons/magnifying-glass-thin.svg';
import { addFilterJson } from 'Features/products';
import useBrandsData from 'Hooks/Global/useBrandsData';
import { encodeData } from 'Utils/encodeBase64';
import useSearchResultData from 'Utils/getSearchResult';
import ThreeDotsLoading from './ThreeDotsLoading';

const useKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

const ListItem = ({
  item,
  sku,
  type,
  active,
  setSelected,
  setHovered,
  selectedSearchItem,
  setSearchValue,
  searchValue,
}) => {
  const navigate = useNavigate();

  const escapedSearchValue = searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(escapedSearchValue, 'gi');
  const highlightedItem = item.replace(regex, (match) => `<strong>${match}</strong>`);
  const brandsData = useBrandsData();

  const constent = (
    <li
      className={`search-item ${active ? 'highlighted' : ''}`}
      role='button'
      tabIndex={-1}
      onClick={() => {
        const matchedBrand = brandsData?.getBrandsList.filter(
          (brand) => brand.title.toLowerCase() === item.toLowerCase(),
        )[0];

        if (matchedBrand) {
          const searchParams = createSearchParams({
            filters: encodeData({
              brand_name: {
                in: [matchedBrand.entity_id],
              },
            }),
            firstFilters: encodeData({
              brand_name: {
                in: [matchedBrand.entity_id],
              },
            }),
          }).toString();
          if (matchedBrand.is_monetized === '1') {
            navigate({
              pathname: `/brand-store/${matchedBrand.url_key}/${matchedBrand.entity_id}`,
              search: searchParams,
            });
          } else {
            navigate({
              pathname: `/product-listing/${matchedBrand.url_key}`,
              search: createSearchParams({
                filters: encodeData({
                  brand_name: {
                    in: [matchedBrand.entity_id],
                  },
                }),
                firstFilters: encodeData({
                  brand_name: {
                    in: [matchedBrand.entity_id],
                  },
                }),
              }).toString(),
            });
          }
        } else if (sku) {
          if (type === 'VirtualProduct') {
            const path = `/product-listing?search=${encodeURIComponent(
              item
                ?.replace(/[^a-zA-Z0-9]/g, '-')
                .replace(/-{2,}/g, '-')
                .toLowerCase() || '',
            )}/${encodeURIComponent(sku)}`;
            navigate(path);
          } else {
            const path = `/brands/${encodeURIComponent(
              item
                ?.replace(/[^a-zA-Z0-9]/g, '-')
                .replace(/-{2,}/g, '-')
                .toLowerCase() || '',
            )}/${encodeURIComponent(sku)}`;

            navigate(path);
          }
        } else {
          selectedSearchItem(item);
          setSelected(item);
        }
      }}
      onMouseEnter={() => {
        setHovered(item);
        setSearchValue(item);
      }}
      onMouseLeave={() => {
        setHovered(undefined);
        setSearchValue('');
      }}
      dangerouslySetInnerHTML={{ __html: highlightedItem }}
    />
  );

  return constent;
};

const MobileSearch = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [prodSuggestions, setProdSuggestion] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, setSelected] = useState(undefined);
  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const [cursor, setCursor] = useState(-50);
  const [hovered, setHovered] = useState(undefined);
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [placeholderLoading] = useState(false);

  const brandsData = useBrandsData();
  const getSearchResultData = useSearchResultData();

  const handleInputFocus = () => {
    setIsInputFocus(true);
    setShowPlaceholder(false);
  };

  const handleInputBlur = () => {
    setIsInputFocus(false);
    if (searchValue?.length === 0) {
      setShowPlaceholder(true);
    }
    setShowPlaceholder(false);
  };

  const getSearchResult = useCallback(
    (value) => {
      searchGtag(value);
      const matchedBrand = brandsData?.getBrandsList.filter(
        (brand) => brand.title.toLowerCase() === value.toLowerCase(),
      )[0];
      if (matchedBrand) {
        const searchParams = createSearchParams({
          filters: encodeData({
            brand_name: {
              in: [matchedBrand.entity_id],
            },
          }),
          firstFilters: encodeData({
            brand_name: {
              in: [matchedBrand.entity_id],
            },
          }),
        }).toString();
        if (matchedBrand.is_monetized === '1') {
          navigate({
            pathname: `/brand-store/${matchedBrand.url_key}/${matchedBrand.entity_id}`,
            search: searchParams,
          });
        } else {
          navigate({
            pathname: `/product-listing/${matchedBrand.url_key}`,
            search: createSearchParams({
              filters: encodeData({
                brand_name: {
                  in: [matchedBrand.entity_id],
                },
              }),
              firstFilters: encodeData({
                brand_name: {
                  in: [matchedBrand.entity_id],
                },
              }),
            }).toString(),
          });
        }
      } else if (!loadingSuggestions && value.length >= 2) {
        const isSpaceOnly = !value.replace(/\s/g, '').length;
        if (!isSpaceOnly && suggestions?.length > 0) {
          localStorage.removeItem('keep_filter');
          localStorage.removeItem('ignore_filter');
          localStorage.removeItem('level_three');
          localStorage.removeItem('currPriceFilter');

          searchRef.current?.blur();
          setSearchValue(() => '');
          setSuggestions(() => []);
          if (searchRef.current) {
            searchRef.current.blur();
            setShowPlaceholder(true);
          }
          navigate({
            pathname: `/product-listing`,
            search: createSearchParams({
              search: encodeURIComponent(value),
            }).toString(),
          });
        } else if (prodSuggestions?.length > 0) {
          setSearchValue(() => '');
          setShowPlaceholder(true);

          navigate({
            pathname: '/product-listing',
            search: createSearchParams({
              search: encodeURIComponent(value),
            }).toString(),
          });
        }
      } else {
        setSuggestions(() => []);
      }
    },
    [loadingSuggestions, navigate],
  );

  useEffect(() => {
    if ((suggestions?.length ?? 0) + prodSuggestions.length && downPress) {
      setCursor((prevState) => {
        setSearchValue(
          suggestions?.at(prevState < (suggestions?.length ?? 0) - 1 ? prevState + 1 : prevState),
        );
        return prevState < (suggestions?.length ?? 0) + prodSuggestions.length - 1
          ? prevState + 1
          : prevState;
      });
    }
  }, [downPress, suggestions, prodSuggestions]);

  useEffect(() => {
    if (suggestions?.length && upPress) {
      setCursor((prevState) => {
        setSearchValue(suggestions?.at(prevState > 0 ? prevState - 1 : prevState));
        return prevState > 0 ? prevState - 1 : prevState;
      });
    }
  }, [upPress, suggestions]);

  useEffect(() => {
    if (suggestions?.length && enterPress) {
      setShowPlaceholder(true);
      if (searchRef.current.value) {
        if (suggestions?.length <= cursor) {
          searchRef.current.blur();
          setSelected(suggestions?.[cursor]);
          setSearchValue(suggestions?.at(cursor));
          getSearchResult(suggestions?.at(cursor));
        } else {
          searchRef.current.blur();
          setSelected(searchRef.current.value);
          setSearchValue(searchRef.current.value);
          getSearchResult(searchRef.current.value);
        }
      }
    } else if (searchRef.current) {
      if (searchRef.current === document.activeElement && enterPress) {
        setShowPlaceholder(true);
        searchRef.current.blur();
        getSearchResult(searchRef.current.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterPress]);

  useEffect(() => {
    if ((suggestions?.length ?? 0) + prodSuggestions.length && hovered) {
      setCursor(suggestions?.indexOf(hovered));
    }
  }, [hovered, suggestions, prodSuggestions]);

  useEffect(() => {
    dispatch(
      addFilterJson({
        type: 'remove_search',
        filter: null,
      }),
    );
    setSearchValue('');
  }, [dispatch, pathname]);

  const getSearchSuggestions = async (value) => {
    const valueLength = value.replace(/\s/g, '').length;
    if (valueLength >= 2) {
      setLoadingSuggestions(true);
      const res = await getSearchResultData(value);
      if (res.data && res.data.hasOwnProperty('data') && res.data.data) {
        const { productSearch } = res.data.data;
        setSuggestions(() => productSearch.suggestions);
        setProdSuggestion(() => productSearch.items);
        setLoadingSuggestions(false);
      }
    } else {
      setSuggestions(() => []);
      setProdSuggestion(() => []);
    }
  };

  const debounce = (inputFunction) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        inputFunction.apply(context, args);
      }, 500);
    };
  };

  const optimizedSearchFn = useCallback(debounce(getSearchSuggestions), []);

  const onSearchChange = async (e) => {
    e.preventDefault();
    const searchKeyword = e.target.value;
    setSearchValue(searchKeyword);
    if (!searchKeyword) {
      setSuggestions([]);
    }
    if (searchKeyword?.length >= 2) {
      optimizedSearchFn(searchKeyword);
    }
  };
  const selectedSearchItem = (item) => {
    getSearchResult(item);
    setSuggestions(() => []);
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      getSearchResult(searchValue);
      window.dataLayer.push({
        event: 'SEARCH',
        buttonName: 'Search',
      });
    }
  };

  useEffect(() => {
    setSuggestions([]);
    setShowPlaceholder(true);
  }, [pathname]);

  useEffect(() => {
    const handleTouchStart = (event) => {
      if (!searchRef.current || !searchRef.current.contains(event.target)) {
        searchRef.current.blur();
      }
    };

    document.addEventListener('touchstart', handleTouchStart);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
    };
  }, []);

  return (
    <div
      className={showPlaceholder ? 'placeholderClassMobile m-search' : 'm-search'}
      onSubmit={() => getSearchResult(searchValue)}
    >
      <div className={isInputFocus ? 'm-search-outline-black' : 'm-search-outline'}>
        <input
          type='search'
          ref={searchRef}
          value={searchValue}
          aria-label='Search'
          id='search'
          onChange={onSearchChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          className='m-search-input'
          autoComplete='off'
          maxLength={255}
        />

        {showPlaceholder && !placeholderLoading && (
          <div className='wrapper placeholder-container'>
            <p>Search for </p>
            <div className='words placeholder-text'>
              <span className='search-for-words'>Brands</span>
              <span className='search-for-words'>Categories</span>
              <span className='search-for-words'>Products</span>
            </div>
          </div>
        )}
        <div className='m-searchbtn'>
          <button
            type='button'
            className='m-searchicon'
            onClick={() => {
              getSearchResult(searchValue);
              window.dataLayer.push({
                event: 'SEARCH',
                buttonName: 'Search',
              });
            }}
            onKeyDown={handleEnter}
            onKeyPress={handleEnter}
          >
            <img
              src={searchIcon}
              onClick={() => getSearchResult(searchRef.current.value)}
              role='button'
              tabIndex={0}
              className='m-search-icon-img'
              alt=''
            />
          </button>
        </div>
        {searchValue !== '' && (
          <div className='closeBtn m-closeBtn'>
            <button type='button' onClick={() => setSearchValue('')}>
              <X size={26} color='#000000' weight='light' />
            </button>
          </div>
        )}
        {searchValue.trim().length > 1 ? (
          loadingSuggestions ? (
            <>
              <div className='searchBackdrop' />
              <div
                className='search-suggestions m-search-suggestions'
                style={{
                  width: '100%',
                  height: '468px',
                  display: 'grid',
                  placeItems: 'center',
                }}
              >
                <ThreeDotsLoading width={9} height={9} />
              </div>
            </>
          ) : suggestions?.length === 0 && prodSuggestions.length === 0 ? (
            <div className='search-suggestions empty-suggestions'>
              <MagnifyingGlass size={64} weight='duotone' />
              <h5>“No Product Found”</h5>
            </div>
          ) : (
            <>
              <div className='searchBackdrop' />
              <div className='search-suggestions'>
                <ul className='searchUl'>
                  {suggestions?.length > 0 && (
                    <>
                      <span className='match-key'>MATCH KEYWORD</span>
                      <div className='match-keyword'>
                        {suggestions?.map((item, i) => (
                          <ListItem
                            key={item.id}
                            active={i === cursor}
                            item={item}
                            type={item?.product?.__typename}
                            setSelected={setSelected}
                            setHovered={setHovered}
                            selectedSearchItem={selectedSearchItem}
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                          />
                        ))}
                      </div>
                    </>
                  )}
                  {prodSuggestions.length > 0 && (
                    <>
                      <span className='match-key'>MATCH PRODUCT</span>
                      <div className='match-keyword'>
                        {prodSuggestions.map((item, i) => (
                          <ListItem
                            key={item.sku}
                            active={(suggestions?.length ?? 0) + i === cursor}
                            item={item.product.name}
                            type={item?.product?.__typename}
                            sku={item.product.sku}
                            setHovered={setHovered}
                            selectedSearchItem={selectedSearchItem}
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </ul>
              </div>
            </>
          )
        ) : null}
      </div>
    </div>
  );
};

export default MobileSearch;
