/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { dispatchActionsOnFirstLoad } from 'Features/global/globalAction';
import {
  EMAIL_LOGIN_OTP_MUTATION,
  GENERATE_CUSTOMER_TOKEN_MOBILE,
  GENERATE_CUSTOMER_TOKEN_MUTATION,
  LOGIN_OTP_MUTATION,
} from 'Gql/query';
import { changeFormType, changeTrigger } from 'Features/login';
import { validSchema } from 'Utils/ValidationSchema/PasswordLoginShema';
import { notification } from 'Utils/Toast';
import { useLocation, useNavigate } from 'react-router-dom';

const SigninWithPassword = ({ formvalue }) => {
  const inputStyle = {
    marginTop: '30px',
    'font-size': '1rem',
    'font-weight': '500',
  };
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [otploading, setOTPLoading] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [GenerateCustomerToken] = useMutation(GENERATE_CUSTOMER_TOKEN_MUTATION);
  const [GenerateCustomerTokenMobile] = useMutation(GENERATE_CUSTOMER_TOKEN_MOBILE);
  const [LoginOTP] = useMutation(LOGIN_OTP_MUTATION);
  const [EmailLoginOTP] = useMutation(EMAIL_LOGIN_OTP_MUTATION, {
    fetchPolicy: 'no-cache',
  });

  // When a user logs in on the guest cart page, they should be redirected to their exact cart page.
  const isUserOnGuestCart = () => {
    if (pathname.startsWith('/checkout/address') && localStorage.getItem('token'))
      navigate('/shopping-cart');
  };

  const initialValues = {
    password: '',
  };
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema: validSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, action) => {
      setLoading(() => true);
      try {
        if (formvalue.type === 'email') {
          await GenerateCustomerToken({
            variables: { email: formvalue.field, password: values.password },
          }).then((result) => {
            if (result.data.hasOwnProperty('errors')) {
              notification.error('Oops! Something went wrong, Please try again');
              throw new Error(result.data.errors[0].message);
            } else if (formvalue.type === 'email') {
              localStorage.setItem('token', result.data.generateCustomerToken.token);
              dispatchEvent(new Event('storage'));
              dispatch(dispatchActionsOnFirstLoad());
            } else {
              localStorage.setItem('token', result.data.generateCustomerTokenMobile.token);
              dispatchEvent(new Event('storage'));
              dispatch(dispatchActionsOnFirstLoad());
            }
            setLoading(() => false);
            dispatch(changeFormType('login'));
            dispatch(changeTrigger());
            notification.success('Yay! You logged in successfully');
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
              event: 'SignIn',
              ecommerce: {
                mobile: formvalue?.field.slice(1),
              },
            });

            action.resetForm();
          });
        } else {
          await GenerateCustomerTokenMobile({
            variables: { mobile: formvalue.field.replace('+', ''), password: values.password },
          }).then((result) => {
            if (result.data.hasOwnProperty('errors')) {
              notification.error('Oops! Something went wrong, Please try again');
              throw new Error(result.data.errors[0].message);
            } else if (formvalue.type === 'email') {
              localStorage.setItem('token', result.data.generateCustomerToken.token);
              dispatchEvent(new Event('storage'));
              dispatch(dispatchActionsOnFirstLoad());
            } else {
              localStorage.setItem('token', result.data.generateCustomerTokenMobile.token);
              dispatchEvent(new Event('storage'));
              dispatch(dispatchActionsOnFirstLoad());
            }
            setLoading(() => false);
            dispatch(changeFormType('login'));
            dispatch(changeTrigger());
            isUserOnGuestCart();
            notification.success('Yay! You logged in successfully');
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
              event: 'SignIn',
              ecommerce: {
                mobile: formvalue?.field.slice(1),
              },
            });
            action.resetForm();
          });
        }
      } catch (error) {
        setLoading(() => false);
        notification.error(error.message);
      }
    },
  });

  const handleOTP = async () => {
    setOTPLoading(() => true);
    try {
      if (formvalue.type !== 'email') {
        await LoginOTP({
          variables: { mobileNumber: formvalue.field.replace('+', ''), websiteId: 1 },
        }).then((SendOtp) => {
          if (SendOtp.data.loginOTP.status) {
            setOTPLoading(() => false);
            dispatch(changeFormType('VerifyOtpForgotPassword'));
          } else {
            throw new Error(SendOtp.data.loginOTP.message);
          }
        });
      } else {
        await EmailLoginOTP({ variables: { email: formvalue.field, websiteId: 1 } }).then(
          (SendOtp) => {
            if (SendOtp.data.emailLoginOTP.status) {
              setOTPLoading(() => false);
              dispatch(changeFormType('VerifyOtpForgotPassword'));
            } else {
              throw new Error(SendOtp.data.emailLoginOTP.message);
            }
          },
        );
      }
    } catch (error) {
      notification.error(error.message);
      setOTPLoading(() => false);
    }
  };

  return (
    <div className='login rounded rounded-3' data-testid='password'>
      <div className='login__form  d-flex flex-column login_with_otp'>
        <h6>Sign-in to your Glamourbook Account</h6>
        <form onSubmit={handleSubmit}>
          <div className='input__holder d-flex flex-column'>
            <label className='input_label' htmlFor='username'>
              Password
            </label>
            <input
              type='password'
              className={`input ${touched.password && errors.password ? 'errorShake' : ''}`}
              id='password'
              onChange={handleChange}
              onBlur={handleBlur}
              data-testid='password-input'
              placeholder='Enter Password'
              value={values.password}
            />
            {touched.password && errors.password && (
              <p className='text-validation'>{errors.password}</p>
            )}
          </div>
          <input
            type='submit'
            className='continue-button'
            data-testid='password-submit'
            onClick={handleSubmit}
            style={{ width: '100%' }}
            value={loading ? 'Loading...' : 'Submit'}
          />
        </form>
        <p className='divider '>OR</p>
        <input
          style={inputStyle}
          type='submit'
          className='continue-button btntrans mb-0'
          data-testid='otp-redirect'
          value={otploading ? 'Loading...' : 'Login With OTP'}
          onClick={handleOTP}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  formvalue: state.login.username,
});

export default connect(mapStateToProps)(SigninWithPassword);
