import React, { useEffect, useState } from 'react';
import './LandingPage.css';
import { useQuery } from '@apollo/client';
import { BANNER_SLIDER } from 'Gql/LandingPages';
import ThreeDotsLoading from 'Components/Global/ThreeDotsLoading';
import SliderWrapper from 'Components/Homepage/SliderWrapper';

function LandingPage() {
  const isDesktop = window.innerWidth > 992;
  const [hasScrolled, setHasScrolled] = useState(() => !isDesktop);

  const { data: sliderData, loading: sliderLoading } = useQuery(BANNER_SLIDER, {
    variables: {
      page_type: 'landing_page',
      type: 'web',
    },
  });

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(true);
      window.removeEventListener('scroll', handleScroll);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return sliderLoading ? (
    <ThreeDotsLoading />
  ) : (
    <>
      {sliderData?.BannerSlider?.slice(0, 2).map((ban) => (
        <SliderWrapper
          data-testid='slider_wrapper'
          key={ban.slider_id}
          banner={ban}
          isDesktop={isDesktop}
        />
      ))}

      {hasScrolled &&
        sliderData?.BannerSlider?.slice(2, sliderData?.BannerSlider.length).map((ban) => (
          <SliderWrapper
            data-testid='slider_wrapper'
            key={ban.slider_id}
            banner={ban}
            isDesktop={isDesktop}
          />
        ))}
    </>
  );
}

export default LandingPage;
