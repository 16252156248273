// SLIDER TYPES
export const CAROUSEL = 'carousel';
export const SLIDER = 'slider';
export const GRID = 'grid';
export const SEC_CAROUSEL = 'secondary_carousel';
export const PROD_SLIDER = 'product_slider';
export const LOOK_SLIDER = 'look';
export const BLOG_SLIDER = 'blog_slider';
export const VIDEO_SLIDER = 'video_slider';
export const IMG_TILES = 'image_tiles';
export const VIDEO_BANNER = 'video_banner';
export const SLIDER_TITLE_DESC = 'slider_with_title_description';
export const OFFER_SLIDER = 'slider_with_offer';
export const BANNER = 'banner';
export const CAT_TILES = 'category_tiles';
export const GRID_IMG_DESC = 'grid_image_description';
export const IMG_TOP_DESC = 'image_top_description';
export const IMG_WITH_DESC = 'image_with_description';
export const VIDEO_PLAYER = 'video_player';
export const CATEGORY_CIRCLE = 'category_circle';
export const SWIRL_SLIDER = 'swirl_slider';
export const FAQ_SLIDER = 'faq_slider';
export const VIRTUAL_PROD_SLIDER = 'virtual_product_slider';
export const BRAND_SLIDER = 'brand_slider';
export const BRAND_CIRCLE_CAROUSEL = 'brand_circle_carousel';
export const BUY_AGAIN = 'buy_again';
export const STRIPE_SLIDER = 'stripe_slider';
export const IMAGE_SLIDER = 'image_slider';
export const ICON_SLIDER = 'icon_slider';
export const STEP_SLIDER = 'step_slider';
export const STATIC_BANNER = 'static_banner';
export const BRAND_LOGO = 'brand_logo_slider';
export const B2B_FAQ = 'b2b_faq_slider';

// MEDIA TYPES
export const LOC_IMG = 'local_image';
export const VIDEO = 'video';
export const YT_VIDEO = 'youtube_video';
export const LOC_VIDEOS = 's3_video';
export const VIMEO_VIDEO = 'vimeo_video';
// DeleteAccount
export const TERMS_AND_CONDITIONS = 'terms & conditions';
export const PRIVACY_POLICY = 'privacy policy';
export const I_AGREE = 'i agree to the ';
export const AND = ' and ';
export const DELETE_ACCOUNT = 'delete account';
export const CANCEL = 'cancel';

// AccountDeleted
export const WE_ARE_SAD = 'we are sad to see you leave';
export const YOUR_ACCOUNT = 'But remember, glamour always finds its way back...';
export const ANY_PENDING = 'Farewell for now and stay Glamorous!';
export const GLAMOURBOOK_HOME = 'glamourbook home';

export const PRICE = 'price';
export const SIMPLE_PROD = 'SimpleProduct';
export const CONFIG_PROD = 'ConfigurableProduct';
export const CONFIG_CART_PROD = 'ConfigurableCartItem';
export const SIMPLE_CART_PROD = 'SimpleCartItem';
export const VIRTUAL_PROD = 'VirtualProduct';
export const VIRTUAL_CART_ITEM = 'VirtualCartItem';
export const OOS = 'OUT_OF_STOCK';
export const SAVE_LATER = 'SaveLater';
export const REVIEW_ATT = 'Overall';
