import { useEffect, useRef, useState } from 'react';
import { GSTSchema } from 'B2B/Utils/ValidationSchema';
import { useFormik } from 'formik';
import { IS_EMAIL_AVAILABLE, VALIDATE_GST } from 'B2B/Gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import downArrow from 'assets/icons/caret-down-arrow.svg';
import {
  setAddress,
  setBusinessType,
  setEmail,
  setEntityName,
  setGSTIn,
  setPrincipalAddress,
  setEntityType,
} from 'B2B/Redux/SignInSignUp/SignIn';
import './GST.css';

function GST({ updateFormType, hasLoggedIn }) {
  const [isEntityTypeDpOpen, setIsEntityTypeDpOpen] = useState(false);
  const entityDropdownRef = useRef(null);

  const dispatch = useDispatch();

  const b2bSignInState = useSelector((state) => state.b2bSignIn);
  const storeConfigState = useSelector((state) => state.storeConfig);

  const [validateGSTQuery] = useLazyQuery(VALIDATE_GST, {
    fetchPolicy: 'no-cache',
  });

  const [isEmailAvailableMutation] = useMutation(IS_EMAIL_AVAILABLE, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (entityDropdownRef.current && !entityDropdownRef.current.contains(event.target)) {
        setIsEntityTypeDpOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [entityDropdownRef]);

  const formikProps = {
    initialValues: {
      email: b2bSignInState?.email,
      businessOption: 'gstin',
      gstin: b2bSignInState?.gstin,
      entityTypeDd: b2bSignInState?.entityType ?? 'Select',
      entityTypeInput: b2bSignInState?.entityType,
      hasLoggedIn,
    },
    validationSchema: GSTSchema,
    validationOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { setFieldError }) => {
      try {
        const isEmailAvailableQueryPromise =
          !hasLoggedIn &&
          new Promise((resolve, reject) => {
            isEmailAvailableMutation({
              variables: {
                email: values.email,
              },
            })
              .then((res) => {
                if (res?.data?.isEmailAvailable?.is_email_available) {
                  resolve();
                } else {
                  setFieldError(
                    'email',
                    'This Email ID is already in use, kindly try with new one.',
                  );
                  reject();
                }
              })
              .catch(() => {
                setFieldError('email', 'An unexpected error occurred.');
                reject();
              });
          });

        const validateGSTQueryPromise =
          values.businessOption === 'gstin' &&
          new Promise((resolve, reject) => {
            validateGSTQuery({
              variables: {
                GST: values.gstin,
              },
            })
              .then((res) => {
                if (res?.data?.validateGST) {
                  dispatch(setGSTIn(values?.gstin));
                  dispatch(setEntityName(res?.data?.validateGST?.name));
                  dispatch(setPrincipalAddress(res?.data?.validateGST?.principal_address));
                  dispatch(setAddress(res?.data?.validateGST?.address));
                  console.log(values);
                  dispatch(
                    setEntityType(
                      values?.entityTypeDd === 'Others'
                        ? values?.entityTypeInput
                        : values?.entityTypeDd,
                    ),
                  );
                  resolve();
                } else {
                  setFieldError('gstin', res?.errors?.[0]?.message);
                  reject();
                }
              })
              .catch(() => {
                setFieldError('gstin', 'An unexpected error occurred.');
                reject();
              });
          });

        Promise.all([isEmailAvailableQueryPromise ?? null, validateGSTQueryPromise ?? null])
          .then(async () => {
            await dispatch(setBusinessType(values.businessOption));
            if (values?.businessOption === 'pan_card') {
              updateFormType('GSTCertificate');
            } else if (values?.businessOption === 'gstin') {
              updateFormType('GSTVerification');
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    },
  };

  const { values, setFieldValue, handleChange, errors, touched, handleBlur, handleSubmit } =
    useFormik(formikProps);

  useEffect(() => {
    dispatch(setEmail(values.email));
    dispatch(setGSTIn(values.gstin));
    dispatch(
      setEntityType(
        values?.entityTypeDd === 'Others' ? values?.entityTypeInput : values?.entityTypeDd,
      ),
    );
  }, [dispatch, values.email, values.gstin, values.entityTypeDd, values.entityTypeInput]);

  const setBusinessOption = (value) => {
    setFieldValue('businessOption', value);
  };

  return (
    <div className='gst'>
      <form onSubmit={handleSubmit}>
        {!hasLoggedIn && (
          <div className='gst-inputHeader'>
            <div className='gst-inputText'>Email ID *</div>
            <input
              placeholder='Enter Email ID'
              name='email'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email && <div className='validationError'>{errors.email}</div>}
          </div>
        )}

        <div className='gst-businessHeading'>entity type*</div>
        <div className='gst-inputHeader'>
          <div
            ref={entityDropdownRef}
            role='button'
            tabIndex={0}
            onClick={() => setIsEntityTypeDpOpen(!isEntityTypeDpOpen)}
          >
            <div className={`gst-entityType ${isEntityTypeDpOpen && 'open'}`}>
              <div
                className={`gst-entityTypeValue ${values?.entityTypeDd !== 'Select' && 'valid'}`}
              >
                {values?.entityTypeDd}
              </div>
              <img className='gst-downArrow' src={downArrow} alt='downArrow' />
            </div>
            {isEntityTypeDpOpen && (
              <div className='gst-entityType-dD'>
                {[
                  'Select',
                  ...(storeConfigState?.b2bEnetityTypes?.split(',') ?? []),
                  'Others',
                ]?.map((value, index) => (
                  <div
                    key={index}
                    role='button'
                    tabIndex={0}
                    onBlur={handleBlur}
                    onClick={() => {
                      setFieldValue('entityTypeDd', value);
                    }}
                  >
                    {value}
                  </div>
                ))}
              </div>
            )}
          </div>
          {values?.entityTypeDd === 'Others' && (
            <>
              <div className='gst-inputText'>{values?.entityTypeDd}*</div>
              <input
                placeholder='Enter Entity Type'
                name='entityTypeInput'
                value={values.entityTypeInput}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </>
          )}

          {(((errors?.entityTypeInput ?? false) && (touched?.entityTypeInput ?? false)) ||
            ((errors?.entityTypeDd ?? false) && (touched?.entityTypeDd ?? false))) && (
            <div className='validationError'>
              {values?.entityTypeDd === 'Others' ? errors?.entityTypeInput : errors?.entityTypeDd}
            </div>
          )}
        </div>

        {!hasLoggedIn && <div className='gst-businessHeading'>business details</div>}
        <div className='gst-inputHeader'>
          {!hasLoggedIn && (
            <div className='gst-selector'>
              <div
                className={`gst-option ${values.businessOption === 'gstin' && 'selected'}`}
                tabIndex={0}
                role='button'
                onClick={() => setBusinessOption('gstin')}
              />
              <div className='gst-Info'>
                GSTIN
                <div>(to avail GST Input Credit)</div>
              </div>
            </div>
          )}
          {values.businessOption === 'gstin' && (
            <>
              <div className='gst-inputText'>GSTIN *</div>
              <input
                placeholder='Enter GSTIN'
                name='gstin'
                maxLength={15}
                value={values.gstin}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.gstin && touched.gstin && (
                <div className='validationError'>{errors.gstin}</div>
              )}
            </>
          )}
        </div>
        {!hasLoggedIn && (
          <div className='gst-inputHeader'>
            <div className='gst-selector'>
              <div
                className={`gst-option ${values.businessOption === 'pan_card' && 'selected'}`}
                tabIndex={0}
                role='button'
                onClick={() => setBusinessOption('pan_card')}
              />
              <div className='gst-Info'>
                Pan Card
                <div>(Artists and Small Businesses)</div>
              </div>
            </div>
          </div>
        )}
        <div className='gstin-btn'>
          <button className='gstin-submit whiteCta' tabIndex={0} type='submit'>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default GST;
