import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import searchIcon from 'assets/icons/magnifying-glass-thin.svg';
import { addFilterJson } from 'Features/products';
import { MagnifyingGlass } from 'phosphor-react';
import { searchGtag } from 'Analytics/dataLayerEvents';
import { encodeData } from 'Utils/encodeBase64';
import useBrandsData from 'Hooks/Global/useBrandsData';
import useSearchResultData from 'Utils/getSearchResult';
import ThreeDotsLoading from './ThreeDotsLoading';

const useKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };
    const upHandler = (e) => {
      if (e.key === 'ArrowUp') {
        e.target.selectionStart = e.target.value?.length;
      }
      if (e.key === targetKey) {
        setKeyPressed(false);
      }
    };
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

const ListItem = ({
  item,
  sku,
  type,
  active,
  setSelected,
  setHovered,
  selectedSearchItem,
  searchValue,
}) => {
  const navigate = useNavigate();
  const brandsData = useBrandsData();

  const escapedSearchValue = searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(escapedSearchValue, 'gi');
  const highlightedItem = item.replace(regex, (match) => `<strong>${match}</strong>`);
  const constent = (
    <li
      className={`search-item ${active ? 'highlighted' : ''}`}
      role='button'
      tabIndex={-1}
      onClick={() => {
        const matchedBrand = brandsData?.getBrandsList.filter(
          (brand) => brand.title.toLowerCase() === item.toLowerCase(),
        )[0];

        if (matchedBrand) {
          const searchParams = createSearchParams({
            filters: encodeData({
              brand_name: {
                in: [matchedBrand.entity_id],
              },
            }),
            firstFilters: encodeData({
              brand_name: {
                in: [matchedBrand.entity_id],
              },
            }),
          }).toString();
          if (matchedBrand.is_monetized === '1') {
            navigate({
              pathname: `/brand-store/${matchedBrand.url_key}/${matchedBrand.entity_id}`,
              search: searchParams,
            });
          } else {
            navigate({
              pathname: `/product-listing/${matchedBrand.url_key}`,
              search: createSearchParams({
                filters: encodeData({
                  brand_name: {
                    in: [matchedBrand.entity_id],
                  },
                }),
                firstFilters: encodeData({
                  brand_name: {
                    in: [matchedBrand.entity_id],
                  },
                }),
              }).toString(),
            });
          }
        } else if (sku) {
          if (type === 'VirtualProduct') {
            const path = `/product-listing?search=${encodeURIComponent(
              item
                ?.replace(/[^a-zA-Z0-9]/g, '-')
                .replace(/-{2,}/g, '-')
                .toLowerCase() || '',
            )}/${encodeURIComponent(sku)}`;
            navigate(path);
          } else {
            const path = `/brands/${encodeURIComponent(
              item
                ?.replace(/[^a-zA-Z0-9]/g, '-')
                .replace(/-{2,}/g, '-')
                .toLowerCase() || '',
            )}/${encodeURIComponent(sku)}`;

            navigate(path);
          }
        } else {
          selectedSearchItem(item);
          setSelected(item);
        }
      }}
      onMouseEnter={() => {
        setHovered(item);
      }}
      onMouseLeave={() => {
        setHovered(undefined);
      }}
      dangerouslySetInnerHTML={{ __html: highlightedItem }}
    />
  );

  return constent;
};

const Search = ({ setShowSearch }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [prodSuggestions, setProdSuggestion] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [getSearchParams] = useSearchParams();
  const [searchChanged] = useState(getSearchParams.get('search'));
  const dispatch = useDispatch();
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [, setSelected] = useState(undefined);
  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const [cursor, setCursor] = useState(null);
  const [hovered, setHovered] = useState(undefined);
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const searchinputRef = useRef(null);

  const brandsData = useBrandsData();
  const getSearchResultData = useSearchResultData();

  const handleClickOutside = (event) => {
    if (searchinputRef.current && !searchinputRef.current.contains(event.target)) {
      setShowSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowSearch]);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        setShowSearch(false);
      }
    });
  }, []);

  useEffect(() => {
    const checkNetworkStatus = () => {
      if (!navigator.onLine) {
        setShowSearch(false);
      }
    };

    checkNetworkStatus();
    window.addEventListener('online', checkNetworkStatus);
    window.addEventListener('offline', checkNetworkStatus);
  }, []);

  const getSearchResult = (value) => {
    searchGtag(value);
    if (!loadingSuggestions && value.length >= 2) {
      const isSpaceOnly = !value.replace(/\s/g, '').length;
      if (!isSpaceOnly && suggestions?.length > 0) {
        localStorage.removeItem('keep_filter');
        localStorage.removeItem('ignore_filter');
        localStorage.removeItem('level_three');
        localStorage.removeItem('currPriceFilter');
        localStorage.removeItem('sortBy');

        setSearchValue(() => '');
        setSuggestions(() => []);
        dispatch(
          addFilterJson({
            type: 'url_filters',
            filter: {},
          }),
        );
        navigate({
          pathname: `/product-listing`,
          search: createSearchParams({
            search: encodeURIComponent(value),
          }).toString(),
        });
      } else {
        setSuggestions(() => []);
        setShowSearch(false);

        if (prodSuggestions?.length > 0) {
          navigate({
            pathname: `/product-listing`,
            search: createSearchParams({
              search: encodeURIComponent(value),
            }).toString(),
          });
        }
      }
    }
  };

  useEffect(() => {
    if ((suggestions?.length ?? 0) + prodSuggestions.length && downPress) {
      switch (cursor) {
        case null:
          setCursor(0);
          break;
        default:
          setCursor((prevState) =>
            prevState < (suggestions?.length ?? 0) + prodSuggestions.length - 1
              ? prevState + 1
              : prevState,
          );
      }
    }
  }, [downPress, suggestions, prodSuggestions]);

  useEffect(() => {
    if (suggestions?.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress, suggestions]);

  useEffect(() => {
    const matchedBrand = brandsData?.getBrandsList.filter(
      (brand) => brand.title.toLowerCase() === searchRef.current.value.toLowerCase(),
    )[0];
    if (matchedBrand) {
      const searchParams = createSearchParams({
        filters: encodeData({
          brand_name: {
            in: [matchedBrand.entity_id],
          },
        }),
        firstFilters: encodeData({
          brand_name: {
            in: [matchedBrand.entity_id],
          },
        }),
      }).toString();
      if (matchedBrand.is_monetized === '1') {
        navigate({
          pathname: `/brand-store/${matchedBrand.url_key}/${matchedBrand.entity_id}`,
          search: searchParams,
        });
      } else {
        navigate({
          pathname: `/product-listing/${matchedBrand.url_key}`,
          search: createSearchParams({
            filters: encodeData({
              brand_name: {
                in: [matchedBrand.entity_id],
              },
            }),
            firstFilters: encodeData({
              brand_name: {
                in: [matchedBrand.entity_id],
              },
            }),
          }).toString(),
        });
      }
    } else if (suggestions?.length && enterPress) {
      setShowSearch(false);
      setSelected(suggestions[cursor]);
      setSearchValue(suggestions?.at(cursor));

      if (cursor === null || cursor === undefined) getSearchResult(searchRef.current.value);
      else if (cursor + 1 > suggestions?.length) {
        const prod = prodSuggestions.at(cursor - (suggestions?.length ?? 0)).product;
        if (prod?.__typename === 'VirtualProduct') {
          if (!loadingSuggestions && prod.name.length >= 2) {
            const isSpaceOnly = !prod.name.replace(/\s/g, '').length;
            if (!isSpaceOnly) {
              setSearchValue(() => '');
              setSuggestions(() => []);
              dispatch(
                addFilterJson({
                  type: 'url_filters',
                  filter: {},
                }),
              );
              navigate({
                pathname: '/product-listing',
                search: createSearchParams({
                  search: encodeURIComponent(prod.name),
                }).toString(),
              });
            } else {
              setSuggestions(() => []);
            }
          }
        } else {
          const path = `/brands/${encodeURIComponent(
            prod.name
              ?.replace(/[^a-zA-Z0-9]/g, '-')
              .replace(/-{2,}/g, '-')
              .toLowerCase() || '',
          )}/${encodeURIComponent(prod.sku)}`;

          navigate(path);
        }
      } else {
        getSearchResult(suggestions?.at(cursor));
      }
    } else {
      setSelected(searchRef.current.value);
      setSearchValue(searchRef.current.value);
      getSearchResult(searchRef.current.value);
    }
  }, [enterPress]);

  useEffect(() => {
    if ((suggestions?.length ?? 0) + prodSuggestions.length && hovered) {
      setCursor(suggestions?.indexOf(hovered));
    }
  }, [hovered, suggestions, prodSuggestions]);

  useEffect(() => {
    setSearchValue('');
    const handleClick = () => {
      setSuggestions([]);
    };
    window.addEventListener('click', handleClick);
    return window.removeEventListener('click', handleClick);
  }, [dispatch, pathname, searchChanged]);

  const getSearchSuggestions = async (value) => {
    const valueLength = value.replace(/\s/g, '').length;
    if (valueLength >= 2) {
      setLoadingSuggestions(true);
      const res = await getSearchResultData(value);
      if (res.data.hasOwnProperty('data')) {
        const { productSearch } = res.data.data;
        setSuggestions(productSearch.suggestions);
        setProdSuggestion(productSearch.items);
        setLoadingSuggestions(false);
      }
    } else {
      setSuggestions(() => []);
      setProdSuggestion(() => []);
    }
  };

  const debounce = (inputFunction) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        inputFunction.apply(context, args);
      }, 500);
    };
  };

  const optimizedSearchFn = useCallback(debounce(getSearchSuggestions), []);

  const onSearchChange = async (e) => {
    e.preventDefault();
    const searchKeyword = e.target.value;

    setSearchValue(searchKeyword);
    if (!searchKeyword) {
      setCursor(null);
      setSuggestions([]);
    }
    if (searchKeyword?.length >= 2) {
      setLoadingSuggestions(true);
      optimizedSearchFn(searchKeyword);
    }
  };

  const selectedSearchItem = (item) => {
    getSearchResult(item);
    setSuggestions(() => []);
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      getSearchResult(searchValue);
      window.dataLayer.push({
        event: 'SEARCH',
        buttonName: 'Search',
      });
    }
  };

  useEffect(() => {
    setSuggestions([]);
  }, [pathname]);

  const handleFocus = () => {
    setShowPlaceholder(false);
  };

  const handleBlur = () => {
    if (searchValue === '') setShowPlaceholder(true);
  };

  return (
    <div ref={searchinputRef}>
      <div
        id='search-width'
        className={
          showPlaceholder
            ? 'd-flex justify-content-center placeholderClass position-relative'
            : 'position-relative d-flex justify-content-center'
        }
      >
        <div className='search header-content' onSubmit={() => getSearchResult(searchValue)}>
          <input
            type='text'
            ref={searchRef}
            value={searchValue}
            dir='auto'
            autoComplete='off'
            aria-label='Search'
            id='search'
            onChange={onSearchChange}
            className='search-input'
            onFocus={handleFocus}
            onBlur={handleBlur}
            maxLength={255}
          />
          <div className='search-button-option'>
            {showPlaceholder && (
              <div className='wrapper'>
                <p>Search for </p>
                <div className='words'>
                  <span className='search-for-words'>Brands</span>
                  <span className='search-for-words'>Categories</span>
                  <span className='search-for-words'>Products</span>
                </div>
              </div>
            )}
          </div>
          <div className='search-button'>
            <img
              type='button'
              data-testid='button-search'
              className='searchicon search-icon-img'
              width={24}
              height={24}
              src={searchIcon}
              onClick={() => {
                getSearchResult(searchValue);
                window.dataLayer.push({
                  event: 'SEARCH',
                  buttonName: 'Search',
                });
              }}
              onKeyDown={handleEnter}
              onKeyPress={handleEnter}
              role='button'
              tabIndex={0}
              alt=''
            />
          </div>
        </div>
      </div>
      {searchValue.trim().length > 1 ? (
        loadingSuggestions ? (
          <div
            className='search-suggestions'
            style={{
              width: '350px',
              height: '450px',
              display: 'grid',
              placeItems: 'center',
              top: '40px',
            }}
          >
            <ThreeDotsLoading width={10} height={10} />
          </div>
        ) : suggestions?.length === 0 && prodSuggestions?.length === 0 ? (
          <div className='search-suggestions-wrapper'>
            <div className='search-suggestions empty-suggestions'>
              <MagnifyingGlass size={64} weight='duotone' />
              <h5>“No Product Found”</h5>
            </div>
          </div>
        ) : (
          <div className='search-suggestions-wrapper'>
            <div className='search-suggestions'>
              <ul className='searchUl'>
                {suggestions?.length > 0 && (
                  <>
                    <span className='match-key'>MATCH KEYWORD</span>
                    <div className='match-keyword'>
                      {suggestions?.map((item, i) => (
                        <ListItem
                          key={item.id}
                          active={i === cursor}
                          item={item}
                          setSelected={setSelected}
                          setHovered={setHovered}
                          selectedSearchItem={selectedSearchItem}
                          setSearchValue={setSearchValue}
                          searchValue={searchValue}
                        />
                      ))}
                    </div>
                  </>
                )}
                {prodSuggestions.length > 0 && (
                  <>
                    <span className='match-key'>MATCH PRODUCT</span>
                    <div className='match-keyword'>
                      {prodSuggestions.map((item, i) => (
                        <ListItem
                          key={item.sku}
                          active={(suggestions?.length ?? 0) + i === cursor}
                          item={item.product.name}
                          sku={item.product.sku}
                          type={item.product.__typename}
                          setHovered={setHovered}
                          selectedSearchItem={selectedSearchItem}
                          setSearchValue={setSearchValue}
                          searchValue={searchValue}
                        />
                      ))}
                    </div>
                  </>
                )}
              </ul>
            </div>
          </div>
        )
      ) : null}
    </div>
  );
};

export default Search;
